.Inventorys .inventory h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Inventorys .inventory p {
  color: #666;
}

.button-add {
  margin-right: 7px;
  top: 2px;
  color: green;
}

.button-remove {
  margin-right: 7px;
  top: 2px;
  color: red;
}