.Locations .location h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Locations .location p {
  color: #666;
}

.button {
  position: absolute;
  top: 50%;
  left: 93%;
  transform: translate(-50%, -50%) !important;
}
